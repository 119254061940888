import React from 'react';
import {BsTwitter, BsLinkedin} from 'react-icons/bs'
import {FaGithub} from 'react-icons/fa'


const SocialMedia = () => {
    return (
        <div className="app__social">
            <div>
                <a href="https://twitter.com/blakebdavies" target="_blank" rel="noreferrer" title="Tweet me!">
                    <BsTwitter/>
                </a>
            </div>
            <div>
                <a href="https://linkedin.com/in/blakebdavies" target="_blank" rel="noreferrer" title="Let's connect on LinkedIn!">
                    <BsLinkedin/>
                </a>
            </div>
            <div>
                <a href="https://github.com/blakebdavies" target="_blank" rel="noreferrer" title="Follow me on Github!">
                    <FaGithub/>
                </a>
            </div>

        </div>
    );
};

export default SocialMedia;
